import 'url-search-params-polyfill'
import 'core-js/es/object/entries'
import 'core-js/es/string/starts-with'

function getQuery() {
  return window.location !== window?.top?.location
    ? document.referrer.substring(document.referrer.indexOf('?') + 1)
    : document.location.search.substring(1)
}

interface PaletteColor {
  main: string
  light: string
  dark: string
  contrastColor: string
}

interface Palette {
  primary: PaletteColor
  secondary: PaletteColor
}

export interface UrlPayload {
  productId?: number
  amount?: number
  purpose?: string
  term?: number
  vehiclePrice?: number
  initialPayment?: number
  formConfig?: string
  resume?: string
  backUrl?: string
  palette?: Palette
  embedded?: boolean
}

export interface Options {
  advertisementId?: string
  amplitudeId?: string | null
  entryPoint?: string | null
  paapiId?: string | null
  version?: string | null
  minAmount?: number
  maxAmount?: number
  hasDac?: boolean
}

export function appendUrlParams(
  url: string,
  urlPayload: UrlPayload = {},
  opts: Options = {},
): string {
  const urlParams = new URLSearchParams(getQuery())

  for (const [name, value] of Object.entries(opts)) {
    if (value === undefined) {
      continue
    }

    urlParams.set(name, value)
  }

  for (const [name, value] of Object.entries(urlPayload)) {
    if (value === undefined || urlParams.get(name)) {
      continue
    }

    if (name === 'palette') {
      urlParams.set(name, JSON.stringify(value))
      continue
    }

    urlParams.set(name, value)
  }

  // taurine-widgets is being run in affiliate context only
  // tell taurine explicitly that it is being run in an affiliate context
  urlParams.set('context', 'affiliate')

  // ANCH-1762: We set affiliateLinkout to true for all affiliates to send them
  // all to the standalone final page instead of the iframe final
  urlParams.set('affiliateLinkout', 'true');

  return `${url}?${urlParams.toString()}`
}
